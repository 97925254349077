.font-success {
  color: #009d00;
  font-weight: 500;
}

.font-bold {
  font-weight: 500;
}

.font-stats {
  font-size: 2.5rem;
  font-weight: 500;
}

.column-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.how-might-we {
  text-align: center;
  width: 100%;
}

.img {
  width: 100%;
}

.img-gray-container {
  background-color: #fafafa;
  border-radius: 12px;
  border: #eeeeee solid 1px;
  grid-column: 1/6;
  padding: 1rem;
  margin: 2rem 0;
}

.img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.img-wrapper-30 {
  width: 90%;
}

.img-wrapper-40 {
  width: 60%;
}

.img-wrapper-50 {
  width: 100%;
  margin: 0;
}

.img-wrapper-60 {
  width: 100%;
}

.img-wrapper-d {
  width: 100%;
  margin: 0;
}

.img-wrapper-d-m {
  width: 100%;
}

.img-wrapper-m {
  width: 40%;
  margin: 0;
}

.images {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

mark {
  background-color: #fffeca;
}

.mobile-back {
  position: fixed;
  top: 5%;
  left: 3%;
  width: 6%;
  display: block;
}

.shop-stats-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#body-proj {
  background-color: white;
  padding: 0 3rem 10rem 3rem;
  height: 100%;
  width: 100%;
  position: fixed;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
  line-height: 1.5rem;
}

#overview-chairish {
  background-image: url("./chairish/chairish-cover.png");
  background-size: cover;
  background-position: center;
}

/* TABLET PORTRAIT */
@media screen and (min-width: 600px) and (orientation: portrait) {
  .column-wrapper {
    align-items: normal;
    width: 48%;
  }

  .grid-item-1-4 {
    grid-column: 1 / 4;
  }

  .how-might-we {
    width: 65%;
  }

  .img-wrapper {
    flex-direction: row;
  }

  .img-wrapper-d {
    margin: 0 1rem;
    width: 90%;
  }

  .img-wrapper-d-m {
    width: 70%;
  }

  .img-wrapper-m {
    margin: 0 1rem;
    width: 20%;
  }

  .img-wrapper-30 {
    width: 50%;
  }

  .img-wrapper-40 {
    width: 40%;
  }

  .img-wrapper-50 {
    margin: 0 1rem;
    width: 80%;
  }

  .img-wrapper-60 {
    width: 60%;
  }

  .img-gray-container {
    grid-column: 1/12;
    padding: 2rem;
  }

  .shop-stats-wrapper {
    width: 48%;
  }
}

/* TABLET LANDSCAPE */
@media screen and (min-width: 600px) and (orientation: landscape) {
  /* .images {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    width: 100%;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  } */

  .column-wrapper {
    align-items: normal;
    width: 48%;
  }

  .how-might-we {
    width: 65%;
  }

  .img-gray-container {
    grid-column: 1/12;
    padding: 3rem;
  }

  .img-wrapper {
    flex-direction: row;
  }

  .img-wrapper-30 {
    width: 40%;
  }

  .img-wrapper-50 {
    width: 60%;
  }

  .img-wrapper-60 {
    width: 60%;
  }

  .img-wrapper-d {
    margin: 0 1rem;
    width: 90%;
  }

  .img-wrapper-d-m {
    margin: 0 1rem;
    width: 70%;
  }

  .img-wrapper-m {
    width: 20%;
    margin: 0 1rem;
  }
}

/* DESKTOP OR IPAD PRO LANDSCAPE */
@media screen and (min-width: 1200px) and (orientation: landscape) {
  .img-gray-container {
    padding: 3rem 5rem;
  }
}

/* XL DESKTOP */
@media screen and (min-width: 1800px) and (orientation: landscape) {
  .img-wrapper-d-m {
    margin: 0 1rem;
    width: 60%;
  }

  .img-wrapper-m {
    width: 15%;
    margin: 0 1rem;
  }

  .img-wrapper-30 {
    width: 30%;
  }

  .img-wrapper-50 {
    width: 45%;
  }

  .img-wrapper-60 {
    width: 50%;
  }
}
