
html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  position: fixed;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {

}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.6);
    background: rgba(35,35,35,1);
    z-index: 3;
}
